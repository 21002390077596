import React from "react"
import css from "./GroupManagementTable.module.scss"
import { handleApiError } from "@app/utils"

interface SettingsColumnsRendererProps {
  settingsColumns: string[][]
  groupSettings: GroupSettings | null
  setGroupSettings: React.Dispatch<React.SetStateAction<GroupSettings | null>>
  canEditSettings: boolean
  settingsMap: Record<string, string>
}

interface GroupSettings {
  block_scheduling: boolean
  batch_import_export: boolean
  multicalendar_report: boolean
  split_shift: boolean
  always_track_splitshift: boolean
  casting_jobs: boolean
  draft_mode: boolean
  multiple_assignments: boolean
  calendar_v2: boolean
  scheduler_edit_group_merged_daily: boolean
  tigerconnect_settings: TigerConnectSetting[] | null
  [key: string]: boolean | string | TigerConnectSetting[] | null
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

const SettingsColumnsRenderer: React.FC<SettingsColumnsRendererProps> = ({
  settingsColumns,
  groupSettings,
  setGroupSettings,
  canEditSettings,
  settingsMap,
}) => {
  return (
    <>
      {settingsColumns.map((column, colIndex) => (
        <div key={colIndex} className={css.groupSettingsColumn}>
          {column.map((key) => {
            const inputId = `group-setting-${key}`
            return (
              <div key={key} className={css.groupSettingsCheckboxItem}>
                <input
                  type="checkbox"
                  id={inputId}
                  checked={
                    typeof groupSettings?.[key] === "boolean"
                      ? (groupSettings[key] as boolean)
                      : false
                  }
                  disabled={!canEditSettings}
                  onChange={(e) => {
                    if (key === "tigerconnect_roles_sync" && e.target.checked) {
                      const isValid =
                        groupSettings?.tigerconnect_settings?.some(
                          (setting) =>
                            setting.tigerconnect_key &&
                            setting.tigerconnect_secret &&
                            setting.organization_token &&
                            setting.integrationid
                        )
                      if (!isValid) {
                        handleApiError({
                          fail: true,
                          message:
                            "You must have one TigerConnect organization configured in order to enable the Roles sync service",
                        })
                        e.target.checked = false
                        return
                      }
                    }
                    setGroupSettings((prev) =>
                      prev ? { ...prev, [key]: e.target.checked } : prev
                    )
                  }}
                />
                <label htmlFor={inputId}>{settingsMap[key]}</label>
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}

export default SettingsColumnsRenderer
