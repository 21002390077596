import React, { useRef, useEffect } from "react"

import { DateFormatter, MonthsAbbrev } from "@app/utils/constants"
import { format, isSameDay } from "date-fns"
import { filterUnassignedJobs } from "@app/services/getUnassignedEvent"
import { useSelector } from "@app/models"
import { IRequest } from "@app/containers/spa/WhiteboardCalendar/data"
import { Flag } from "@material-ui/icons"
import {
  AssignmentElement,
  RequestElement,
  DayOffElement,
  VacationElement,
  UnassignedElement,
  NoteElement,
} from "../CalendarDayElements"
import AddIcon from "@material-ui/icons/Add"
import { getGroupedDayAssignments } from "@app/services/getGroupedDayAssignments"
import { isHighlightedChange } from "@app/services/getAdditionalAssignmentHighlighted"
import useFilterAssignments from "@app/containers/spa/WhiteboardCalendar/hooks/useFilterAssignments"
import useFilterFlags from "@app/containers/spa/WhiteboardCalendar/hooks/useFilterFlags"

import css from "./CalendarGridView.module.scss"
import cx from "classnames"
import { debounce } from "lodash"

type Props = {
  day: Date
  startDate: Date
  endDate: Date
  jobs: any[]
  requests: any[]
  holidays: any[]
  showModal: (data: AssignmentBaseType) => void
  showModalToNewAssignments: (date: string, jobid: number) => void
  showDateModal: (date: string) => void
  setSelectedRequestid: React.Dispatch<React.SetStateAction<number | undefined>>
  handleNotesModalOpen: (date: string) => void
  handleScheduleChangesModalOpen: (date: string) => void
  isScrolled?: boolean
  indexRow?: number
  isDayView?: boolean
}

export default ({
  day,
  startDate,
  endDate,
  jobs,
  requests: dailyRequests,
  holidays: holidaysByDate,
  showModal,
  showModalToNewAssignments,
  showDateModal,
  setSelectedRequestid,
  handleNotesModalOpen,
  handleScheduleChangesModalOpen,
  isScrolled,
  indexRow,
  isDayView,
}: Props) => {
  const tdRef = useRef<HTMLTableCellElement>(null)
  const [activeRowIndex, setActiveRowIndex] = React.useState<number | null>(0)
  const [previousRowIndex, setPreviousRowIndex] = React.useState<number | null>(
    null
  )
  const [activeRowDay, setActiveRowDay] = React.useState<Date | null>(null)

  // Updates the active row's day information when the activeRowIndex changes
  useEffect(() => {
    if (activeRowIndex !== null) {
      const activeRow = document.querySelector(
        `tr[data-index="${activeRowIndex}"]`
      ) as HTMLElement

      if (activeRow) {
        const newDay = new Date(activeRow.getAttribute("data-date") || "")
        if (newDay.toString() !== "Invalid Date" && newDay !== activeRowDay) {
          setActiveRowDay(newDay)
        }
      }
    }
  }, [activeRowIndex])

  // Keeps track of the previous active row index for comparison
  useEffect(() => {
    if (activeRowIndex !== null && activeRowIndex !== previousRowIndex) {
      setPreviousRowIndex(activeRowIndex)
    }
  }, [activeRowIndex, previousRowIndex])

  // Handles scroll events to dynamically update the activeRowIndex based on the element's position
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (tdRef.current) {
        const { top } = tdRef.current.getBoundingClientRect()
        const headerOffset = 170
        if (top <= headerOffset && activeRowIndex !== indexRow) {
          setActiveRowIndex(indexRow!)
        }

        if (top > headerOffset && activeRowIndex === indexRow) {
          setActiveRowIndex(indexRow! - 1 >= 0 ? indexRow! - 1 : 0)
        }
      }
    }, 100)
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [indexRow, activeRowIndex])

  const dailyScheduleChanges =
    useSelector(
      (state) =>
        state.calendarEvents.dailyAssignments.dailyScheduleChanges[
          format(day, "yyyy-MM-dd")
        ]
    ) || []

  const getTypeGroupedDayOffs = (dayoffs: Dayoff[]) => {
    let typedDayoffs: Record<string, Dayoff[]> = {}

    dayoffs.forEach((dayoff: Dayoff) => {
      if (dayoff.type_abbrev in typedDayoffs) {
        let tmp = typedDayoffs[dayoff.type_abbrev]
        typedDayoffs[dayoff.type_abbrev] = [...tmp, dayoff]
      } else {
        typedDayoffs[dayoff.type_abbrev] = [dayoff]
      }
    })

    return typedDayoffs
  }

  const isWithinPeriod = day >= startDate && day < endDate
  const monthAbbrev = MonthsAbbrev[day.getMonth()]

  const dayIndex = format(day, DateFormatter.normal)

  const {
    dailyNotes: notes,
    dailyVacations: vacations,
    dailyDaysOff: dayoffs,
  } = useSelector((state) => state.calendarEvents.dailyAssignments)
  const dailyNotes = notes[dayIndex] || []
  const dailyVacations = vacations[dayIndex] || []
  const dailyDayoffs = getTypeGroupedDayOffs(dayoffs[dayIndex] || [])

  const {
    calendarConfig: {
      isMissingAssignmentsFilterActive,
      isHighlightedChangesActive,
      filterOptions: { providersFilters, jobsFilters },
    },
  } = useSelector((state) => state.calendarEvents)

  const dayAssignments = useFilterAssignments(dayIndex)
  const dayFlags = useFilterFlags(dayIndex)

  const groupedDayAssignments = getGroupedDayAssignments(dayAssignments, day)

  const unassignedJobs =
    providersFilters.providerIds && providersFilters.providerIds?.length > 0
      ? []
      : filterUnassignedJobs(
          jobs,
          dayIndex,
          dayAssignments.filter((assignment) => assignment.edate === dayIndex)
        ).filter((job: JobUnassigned) => {
          return jobsFilters.jobIds
            ? jobsFilters.jobIds.includes(job.jobid)
            : true
        })

  let assignmentDetails: JSX.Element[] = []
  let flagDetails: JSX.Element[] = []
  let dayoffDetails: JSX.Element[] = []
  let requestDetails: JSX.Element[] = []
  let vacationDetails: JSX.Element[] = []
  let unassignedDetails: JSX.Element[] = []
  let noteDetails: JSX.Element[] = []

  dayFlags.forEach((flag: ScheduleDateType, index: number) => {
    flagDetails.push(
      <div key={index}>
        <span
          onClick={() =>
            showModalToNewAssignments(flag.link_date.link_date, flag.job.jobid)
          }
          className={css.jobAbbrev}
        >
          {flag.job.abbrev}
        </span>
        <Flag style={{ color: "red" }} className={css.flagIcon} />
      </div>
    )
  })

  groupedDayAssignments.forEach((assignment: AssignmentBaseType) => {
    const splitShiftKey = assignment.split_shift
      ? `-${assignment.split_shift?.starttime}-${assignment.split_shift?.endtime}`
      : ""

    const isAssignmentHighlighted = isHighlightedChange(
      isHighlightedChangesActive,
      dailyScheduleChanges,
      assignment
    )

    assignmentDetails.push(
      <AssignmentElement
        assignment={assignment}
        showModal={showModal}
        key={
          assignment.eventid
            ? `event-${assignment.eventid}-${assignment.job?.jobid}${splitShiftKey}`
            : `draft-${assignment.draft_eventid}-${assignment.job?.jobid}${splitShiftKey}`
        }
        isHighlightedChanges={isAssignmentHighlighted}
      />
    )
  })

  for (const [typeAbbrev, dayoffs] of Object.entries(dailyDayoffs)) {
    dayoffDetails.push(
      <DayOffElement
        dayOffProviderId={
          dayoffs.map((dayOff: any) => dayOff.provider.providerid)?.[0]
        }
        dayOffTypeName={typeAbbrev}
        dayoffProviders={dayoffs
          .map((dayoff: Dayoff) => dayoff.provider.display_name)
          .join(", ")}
        key={`${typeAbbrev}-approvedDayoff`}
      />
    )
  }

  if (dailyVacations.length > 0) {
    vacationDetails.push(
      <VacationElement
        vacationProviderId={
          dailyVacations.map((vacation) => vacation.provider.providerid)?.[0]
        }
        vacationTypeName="v"
        vacationProviders={dailyVacations
          .map((vacation: Vacation) => vacation.provider.display_name)
          .join(", ")}
        key={`${day}-approvedVacation`}
      />
    )
  }
  if (dailyNotes.length > 0) {
    dailyNotes.forEach((dailyNote) => {
      if (dailyNote.show_on_calendar) {
        noteDetails.push(
          <NoteElement
            key={`${dailyNote.noteid}-${dailyNote.userid}`}
            content={dailyNote.note}
          />
        )
      }
    })
  }

  dailyRequests.forEach((request: IRequest) => {
    requestDetails.push(
      <RequestElement
        request={request}
        setSelectedRequestid={setSelectedRequestid}
        key={`${request.requestid}-pendingRequest-${request.jobid}-${request.requestorid}`}
      />
    )
  })

  unassignedJobs.forEach((job: JobUnassigned) => {
    unassignedDetails.push(
      <UnassignedElement
        assignment={job}
        key={job.jobid}
        onClick={showModalToNewAssignments}
        date={dayIndex}
      />
    )
  })

  const renderDetails = () => {
    if (isMissingAssignmentsFilterActive) {
      return <>{unassignedDetails}</>
    }
    return (
      <>
        {assignmentDetails}
        {assignmentDetails.length === 0 && flagDetails.length === 0 && (
          <p className={css.providerName}>
            <span>No Assignments</span>
          </p>
        )}
        {unassignedDetails}
        {flagDetails}
        {dayoffDetails}
        {vacationDetails}
        {requestDetails}
        {noteDetails}
      </>
    )
  }

  const isToday = isSameDay(new Date(day), new Date())

  const dayElement = (
    <td
      data-index={indexRow}
      data-date={day.toISOString()}
      key={day.toISOString()}
      className={cx(isWithinPeriod ? css.backgroundDay : css.otherMonthDay, {
        [css.todayOutlined]: isToday,
      })}
      ref={tdRef}
    >
      <div className={css.dayContainer}>
        <>
          <div>
            <div className={css.dayGridHeaderContent}>
              <div
                className={cx(css.dayGridHeaderWrapper, {
                  [css.fixedHeader]: isScrolled && indexRow === activeRowIndex,
                  [css.marginDayView]:
                    isScrolled && indexRow === activeRowIndex && isDayView,
                  [css.marginDefault]:
                    isScrolled && indexRow === activeRowIndex && !isDayView,
                  [css.defaultHeader]: !(
                    isScrolled && indexRow === activeRowIndex
                  ),
                })}
              >
                <p className={css.dayGridHeaderDate}>
                  {activeRowDay
                    ? `${
                        MonthsAbbrev[activeRowDay.getMonth()]
                      } ${activeRowDay.getDate()}`
                    : `${monthAbbrev} ${day.getDate()}`}
                  <div className={css.dayGridHeaderHolidaysWrapper}>
                    {holidaysByDate.map((holidayByDate) => (
                      <div key={holidayByDate.holidayId}>
                        {holidayByDate.name}
                      </div>
                    ))}
                  </div>
                </p>
                <div className={css.dayGridHeaderRightContainer}>
                  {dailyScheduleChanges.length > 0 ? (
                    <button
                      onClick={() => handleScheduleChangesModalOpen(dayIndex)}
                      className={css.trackChanges}
                    >
                      <img src="/art/calendar/delta.png" />
                    </button>
                  ) : null}

                  {dailyNotes.length > 0 ? (
                    <button
                      onClick={() => handleNotesModalOpen(dayIndex)}
                      className={css.noteButton}
                    >
                      Notes
                    </button>
                  ) : null}
                  <div className={css.dayGridHeaderAddContainer}>
                    <a
                      onClick={() => showDateModal(dayIndex)}
                      className="hover-fade"
                    >
                      <AddIcon className={css.addIconColor} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={css.monthDetails}
            style={
              isScrolled && activeRowIndex === indexRow && activeRowIndex !== 0
                ? { marginTop: "50px" }
                : {}
            }
          >
            <div>
              <div className="row">
                <div className="col-xs-12 month-detail-events">
                  <div className={css.assignmentsWrapper}>
                    {renderDetails()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </td>
  )

  return dayElement
}
