import React, { Dispatch, SetStateAction } from "react"
import { addDays, format, parseISO } from "date-fns"
import { DateFormatter } from "@app/utils/constants"
import { IRequest } from "../../data"
import {
  getHolidaysOffDate,
  ProcessedHoliday,
} from "@app/services/getOrderedHolidays"
import SingleDayGrid from "./SingleDayGrid"

type PropsType = {
  weekIndex: number
  startDate: Date
  endDate: Date
  scheduleChanges: ScheduleChange[]
  flags: ScheduleDateType[]
  daysOff: Dayoff[]
  vacations: Vacation[]
  requests: IRequest[]
  jobs: JobAssignment[]
  orderedHolidays: ProcessedHoliday[]
  showModal: (data: AssignmentBaseType) => void
  showModalToNewAssignments: (edate: string, jobid: number) => void
  setSelectedRequestid: Dispatch<SetStateAction<number | undefined>>
  handleNotesModalOpen: (edate: string) => void
  showDateModal: (edate: string) => void
  handleScheduleChangesModalOpen: (edate: string) => void
  isScrolled: boolean
  indexRow: number
}

export default ({
  weekIndex,
  startDate,
  endDate,
  scheduleChanges,
  flags,
  daysOff,
  vacations,
  requests,
  jobs,
  orderedHolidays,
  showModal,
  showModalToNewAssignments,
  setSelectedRequestid,
  handleNotesModalOpen,
  showDateModal,
  handleScheduleChangesModalOpen,
  isScrolled,
  indexRow,
}: PropsType) => {
  const getDailyRequests = (requests: IRequest[], day: Date) =>
    requests?.filter((request: IRequest) => {
      const requestStartDate = parseISO(request.start_date)
      const requestEndDate = parseISO(request.end_date)
      return day >= requestStartDate && day <= requestEndDate
    }) || []

  const singleDayElements = []

  const startDateForElements = addDays(startDate, weekIndex * 7)

  for (let i = 0; i <= 6; i++) {
    const dateForElement = addDays(startDateForElements, i)
    const dayIndex = format(dateForElement, DateFormatter.normal)
    const dailyRequests = getDailyRequests(requests, dateForElement)
    const holidaysByDate = getHolidaysOffDate(dayIndex, orderedHolidays)

    singleDayElements.push(
      <SingleDayGrid
        key={dayIndex}
        day={dateForElement}
        startDate={startDate}
        endDate={endDate}
        jobs={jobs}
        requests={dailyRequests}
        holidays={holidaysByDate}
        showModal={showModal}
        showModalToNewAssignments={showModalToNewAssignments}
        setSelectedRequestid={setSelectedRequestid}
        handleNotesModalOpen={handleNotesModalOpen}
        showDateModal={showDateModal}
        handleScheduleChangesModalOpen={handleScheduleChangesModalOpen}
        isScrolled={isScrolled}
        indexRow={indexRow}
      />
    )
  }

  return <>{singleDayElements.map((element) => element)}</>
}
