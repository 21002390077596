import React, { useState, useMemo, useRef, useEffect } from "react"
import { cloneDeep } from "lodash"
import css from "./CalendarGridView.module.scss"
import AssignmentContentModal from "../AssignmentContentModal/AssignmentContentModal"
import { format, parseISO, differenceInDays } from "date-fns"
import JobsTableModal from "../JobsTableModal/JobsTableModal"
import RequestModal from "../RequestModal"
import cx from "classnames"
import { Provider, IRequest } from "@app/containers/spa/WhiteboardCalendar/data"
import {
  ProcessedHoliday,
  getHolidaysOffDate,
} from "@app/services/getOrderedHolidays"
import { useDispatch, useSelector } from "@app/models"
import { DaysOfWeek } from "@app/utils/constants"
import NotesModal from "../NotesModal"
import ScheduleChangesModal from "../ScheduleChangesModal"
import SingleDayGrid from "./SingleDayGrid"
import CalendarGridRow from "./CalendarGridRow"
import { TableVirtuoso } from "react-virtuoso"
interface CalendarProps {
  view: "Day" | "Week" | "Month"
  selectedDate: string
  periodCount: number
  dayoffs: Dayoff[]
  vacations: Vacation[]
  requests: IRequest[]
  providers: Provider[]
  jobs: JobAssignment[]
  filteredJobs: JobAssignment[]
  daysOffTypes: any
  requestStatus: any
  orderedHolidays: ProcessedHoliday[]
  refreshAssignments: () => void
  scheduleChanges: ScheduleChange[]
}

const getDailyRequests = (requests: IRequest[], day: Date) =>
  requests?.filter((request: IRequest) => {
    const requestStartDate = parseISO(request.start_date)
    const requestEndDate = parseISO(request.end_date)
    return day >= requestStartDate && day <= requestEndDate
  }) || []

const Calendar = ({
  view,
  selectedDate,
  periodCount,
  dayoffs,
  vacations,
  requests,
  providers,
  jobs,
  filteredJobs,
  orderedHolidays,
  refreshAssignments,
  scheduleChanges,
}: CalendarProps) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDateOpen, setIsModalDateOpen] = useState(false)
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentBaseType>()
  const [formatDate, setFormatDate] = useState("")
  const [selectedRequestid, setSelectedRequestid] = useState<
    number | undefined
  >()
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isScheduleChangesModalOpen, setIsScheduleChangesModalOpen] =
    useState(false)
  const [selectedCurrentDate, setSelectedCurrentDate] = useState("")
  const [selectedCurrentDateStr, setSelectedCurrentDateStr] = useState("")
  const dispatch = useDispatch()

  const { startDate: startDateStr, endDate: endDateStr } = useSelector(
    (state) => state.calendarEvents.calendarConfig.filterOptions
  )
  const [isScrolled, setIsScrolled] = useState(false)

  // Detects scroll to toggle the fixed header for dates when scrolling
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      setIsScrolled(scrollTop > 170)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isScrolled])

  const startDate = parseISO(startDateStr)
  const endDate = parseISO(endDateStr)

  const handleNotesModalOpen = (edate: string) => {
    setIsNotesModalOpen(true)
    const parsedDate = format(parseISO(edate), "EEEE, MM/dd/yy")
    setSelectedCurrentDate(parsedDate)
  }

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false)
    setSelectedCurrentDate("")
  }

  const handleScheduleChangesModalOpen = (edate: string) => {
    setIsScheduleChangesModalOpen(true)
    setSelectedCurrentDateStr(edate)
  }

  const handleScheduleChangesModalClose = () => {
    setIsScheduleChangesModalOpen(false)
    setSelectedCurrentDateStr("")
  }

  const showModal = (data: AssignmentBaseType) => {
    setIsModalOpen(true)
    setSelectedAssignment(cloneDeep(data))
  }

  const showModalToNewAssignments = (edate: string, jobid: number) => {
    setSelectedAssignment({ jobid, edate } as AssignmentBaseType)
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setSelectedAssignment(undefined)
    dispatch.calendarEvents.getCalendarDataWithoutEvents()
    setIsModalOpen(false)
  }

  const showDateModal = (date: string) => {
    setFormatDate(date)
    setIsModalDateOpen(true)
  }

  const hideDateModal = () => {
    setIsModalDateOpen(false)
  }

  const { flags } = useSelector((state) => state.calendarEvents.events)

  const weeksToRender = useMemo(() => {
    return (differenceInDays(endDate, startDate) + 1) / 7
  }, [startDate, endDate])

  const renderHeader = () => {
    if (view === "Day") {
      return null
    }
    const startDayIndex = startDate?.getDay()
    return (
      <tr>
        {[...Array(7).keys()].map((i) => {
          const dayIndex = (startDayIndex + i) % 7
          return (
            <th
              className={cx(css.headWeekday, {
                [css.headWeekend]:
                  DaysOfWeek[dayIndex].includes("Saturday") ||
                  DaysOfWeek[dayIndex].includes("Sunday"),
              })}
              key={i}
            >
              {DaysOfWeek[dayIndex]}
            </th>
          )
        })}
      </tr>
    )
  }

  const renderDays = () => {
    if (view === "Day" && periodCount >= 1) {
      const daysElements: JSX.Element[] = []
      let day = new Date(startDate)
      for (let i = 0; i < periodCount; i++) {
        const header = (
          <tr key={`header-${day.toISOString()}`}>
            <th
              className={cx(css.headDay, {
                [css.headWeekend]:
                  DaysOfWeek[day.getDay()].includes("Saturday") ||
                  DaysOfWeek[day.getDay()].includes("Sunday"),
              })}
              colSpan={7}
            >
              {format(day, "EEEE, d MMMM")}
            </th>
          </tr>
        )
        daysElements.push(header)

        const dayIndex = format(day, "yyyy-MM-dd")
        const dailyRequests = getDailyRequests(requests, day)
        const holidaysByDate = getHolidaysOffDate(dayIndex, orderedHolidays)

        const dayContent = (
          <tr key={day.toISOString()}>
            <SingleDayGrid
              key={dayIndex}
              day={new Date(day)}
              startDate={startDate}
              endDate={endDate}
              jobs={jobs}
              requests={dailyRequests}
              holidays={holidaysByDate}
              showModal={showModal}
              showModalToNewAssignments={showModalToNewAssignments}
              setSelectedRequestid={setSelectedRequestid}
              handleNotesModalOpen={handleNotesModalOpen}
              showDateModal={showDateModal}
              handleScheduleChangesModalOpen={handleScheduleChangesModalOpen}
              isScrolled={isScrolled}
              isDayView
            />
          </tr>
        )
        daysElements.push(dayContent)

        day = new Date(day.setDate(day.getDate() + 1))
      }

      return (
        <table className={`table calendar-month ${css.tableContainer}`}>
          <tbody>{daysElements.map((element) => element)}</tbody>
        </table>
      )
    } else {
      const CustomTable = React.forwardRef<
        HTMLTableElement,
        React.HTMLProps<HTMLTableElement>
      >((props, ref) => {
        return (
          <table
            {...props}
            ref={ref}
            className={`table calendar-month ${css.tableContainer}`}
          />
        )
      })
      return (
        <TableVirtuoso
          data={Array.from({
            length: view === "Month" ? weeksToRender : periodCount,
          })}
          components={{
            Table: CustomTable,
          }}
          useWindowScroll
          itemContent={(index) => (
            <CalendarGridRow
              weekIndex={index}
              startDate={startDate}
              endDate={endDate}
              scheduleChanges={scheduleChanges}
              flags={flags}
              daysOff={dayoffs}
              vacations={vacations}
              requests={requests}
              jobs={jobs}
              orderedHolidays={orderedHolidays}
              showModal={showModal}
              showModalToNewAssignments={showModalToNewAssignments}
              setSelectedRequestid={setSelectedRequestid}
              handleNotesModalOpen={handleNotesModalOpen}
              showDateModal={showDateModal}
              handleScheduleChangesModalOpen={handleScheduleChangesModalOpen}
              isScrolled={isScrolled}
              indexRow={index}
            />
          )}
        />
      )
    }
  }

  return (
    <div className={css.calendarContainer} ref={calendarContainerRef}>
      <div className={css.headerContainer}>
        <table className="table calendar-month">
          <thead>{renderHeader()}</thead>
        </table>
      </div>
      <div className={css.daysContainer}>{renderDays()}</div>
      {selectedAssignment && (
        <AssignmentContentModal
          show={isModalOpen}
          hideModal={hideModal}
          selectedAssignment={selectedAssignment}
          jobid={selectedAssignment.jobid}
          edate={selectedAssignment.edate}
        />
      )}
      {selectedRequestid && (
        <RequestModal
          requestid={selectedRequestid}
          onHide={() => {
            setSelectedRequestid(undefined)
            hideModal()
          }}
        />
      )}
      {isModalDateOpen && (
        <JobsTableModal
          centered={true}
          isModalDateOpen={isModalDateOpen}
          onHide={hideDateModal}
          date={formatDate}
          onCancel={hideDateModal}
          onSave={hideDateModal}
          closeButton={true}
          size="xl"
        />
      )}
      <NotesModal
        date={selectedCurrentDate}
        isModalOpen={isNotesModalOpen}
        onCloseModal={handleNotesModalClose}
        closeButton
      />
      <ScheduleChangesModal
        date={selectedCurrentDateStr}
        isModalOpen={isScheduleChangesModalOpen}
        onCloseModal={handleScheduleChangesModalClose}
      />
    </div>
  )
}

export default Calendar
