import React, { Fragment } from "react"
import Tooltip from "@app/components/Tooltip"
import css from "./GroupManagementTable.module.scss"
import { CheckCircle, Cancel } from "@material-ui/icons"

interface SettingsTableData {
  label: string
  value: string
  integrationid?: string
  two_way_sync?: boolean
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

interface SyncSettingsTableTableProps {
  settingsTableData: SettingsTableData[]
  editingRowIndex: number | null
  editingRowData: Record<number, TigerConnectSetting>
  setEditingRowIndex: React.Dispatch<React.SetStateAction<number | null>>
  setIsEditingRow: React.Dispatch<React.SetStateAction<boolean>>
  setEditingRowData: React.Dispatch<
    React.SetStateAction<Record<number, TigerConnectSetting>>
  >
  handleEditRow: (index: number) => void
}

const SyncSettingsTable: React.FC<SyncSettingsTableTableProps> = ({
  settingsTableData,
  editingRowIndex,
  editingRowData,
  setEditingRowIndex,
  setIsEditingRow,
  setEditingRowData,
  handleEditRow,
}) => {
  const isValueEnabled = (value: string | boolean): boolean => {
    if (typeof value === "boolean") {
      return value
    }
    return value !== "N/A" && value !== "0" && value.trim() !== ""
  }

  const renderStatusIcon = (enabled: boolean) => {
    return enabled ? (
      <CheckCircle style={{ color: "green" }} />
    ) : (
      <Cancel style={{ color: "red" }} />
    )
  }

  return (
    <table className={css.groupSettingsTable}>
      <thead>
        <tr className={css.groupSettingsTable}>
          <th className={css.tokenColumn}>Label</th>
          <th className={css.tokenColumn}>Org Token</th>
          <th className={css.tokenColumn}>API Key</th>
          <th className={css.tokenColumn}>Integration ID</th>
          <th className={css.tokenColumn}>2-way Sync</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {settingsTableData.map((data, index) => {
          const isEditing = editingRowIndex === index
          const rowData = editingRowData[index] || {}
          const [orgToken, apiKey, intId] = data.value
            .split(",")
            .map((item) => item.split(":")[1]?.trim() || "N/A")

          if (data.label === "0") {
            return (
              <tr key={index} className={css.groupSettingsTable}>
                <td colSpan={7} className={css.noDataRow}>
                  No TigerConnect organizations available for this group.
                </td>
              </tr>
            )
          }

          return (
            <Fragment key={index}>
              <tr className={css.groupSettingsTable}>
                <td className={css.tokenColumn}>
                  {data.label.length > 25 ? (
                    <Tooltip title={data.label}>
                      <span>{data.label}</span>
                    </Tooltip>
                  ) : (
                    <span>{data.label}</span>
                  )}
                </td>
                <td className={css.tokenColumn}>
                  <Tooltip title={orgToken}>
                    <span>{orgToken}</span>
                  </Tooltip>
                </td>

                <td className={css.tokenColumn}>
                  {renderStatusIcon(isValueEnabled(apiKey))}
                </td>
                <td className={css.tokenColumn}>
                  {renderStatusIcon(isValueEnabled(intId))}
                </td>
                <td className={css.tokenColumn}>
                  {renderStatusIcon(!!data.two_way_sync)}
                </td>
                <td>
                  <button
                    onClick={() => {
                      if (isEditing) {
                        setEditingRowIndex(null)
                        setIsEditingRow(false)
                      } else {
                        handleEditRow(index)
                      }
                    }}
                    className={`${css.editSettings} ${css.hiddenElement}`}
                  >
                    Edit
                  </button>
                </td>
              </tr>
              {isEditing && (
                <tr className={css.editRow}>
                  <td colSpan={5}>
                    <div className={css.groupContainer}>
                      <div>
                        <h3>Integration ID:</h3>
                        <input
                          type="text"
                          value={rowData?.integrationid || ""}
                          onChange={(e) => {
                            setEditingRowData((prev) => ({
                              ...prev,
                              [index]: {
                                ...prev[index],
                                integrationid: e.target.value,
                              },
                            }))
                          }}
                        />
                      </div>
                      <div
                        className={`${css.groupContainer} ${css.twoWaySyncContainer}`}
                      >
                        <h3>2-Way Sync Enabled:</h3>
                        <input
                          type="checkbox"
                          checked={rowData?.two_way_sync || false}
                          onChange={(e) => {
                            setEditingRowData((prev) => ({
                              ...prev,
                              [index]: {
                                ...prev[index],
                                two_way_sync: e.target.checked,
                              },
                            }))
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default SyncSettingsTable
